<template>
  <div>
    <b-card
      border-variant="primary"
      bg-variant="transparent"
      class="shadow-none custom-card text-center"
      title="File Archive"
    >

      <!-- Dropdowns for filtering -->
      <div class="filter-dropdowns" v-if="files.length > 0">
        <b-form-group label="Select Year">
          <b-form-select placeholder="Year" v-model="selectedYear" size="sm" :options="years" @change="filterFiles"></b-form-select>
        </b-form-group>

        <b-form-group label="Select Week" size="sm">
          <b-form-select placeholder= "Year" v-model="selectedWeek" size="sm" :options="weeks" @change="filterFiles"></b-form-select>
        </b-form-group>
      </div>

      <!-- Collapse Component for Files -->
      <app-collapse accordion :type="collapseType">
        <app-collapse-item
          v-for="(file, index) in filteredFiles"
          :key="index"
          :title="file"
        >
          <div class="file-actions">
            <b-form-checkbox v-model="selectedFiles" :value="file">Select</b-form-checkbox>
            <b-button size="sm" variant="outline-primary" @click="downloadFile(file)">Download</b-button>
            <b-button size="sm" variant="outline-secondary" :to="{ name: 'data-summary-preview', params: { fileId: encodeURIComponent(file) }}">View Analysis</b-button>
            <b-button size="sm" variant="outline-danger" icon="TrashIcon">Dummy Button 2</b-button>
          </div>
        </app-collapse-item>
      </app-collapse>

      <b-button @click="compare" variant="primary">Compare Selected Files</b-button>

      <!-- Optional: Error handling alert -->
      <b-alert v-if="error" variant="danger" show>{{ error }}</b-alert>
    </b-card>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { BFormCheckbox, BFormGroup, BFormSelect, BButton, BCard, BAlert } from 'bootstrap-vue'
import axios from 'axios'
import { serverUri } from '@/config'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
    BButton,
    BCard,
    BAlert
  },
  data() {
    return {
      collapseType: 'default',
      files: [], // List of files to display
      selectedFiles: [], // Files selected for comparison
      selectedYear: '', // Currently selected year
      selectedWeek: '', // Currently selected week
      filteredFiles: [], // Filtered list of files to display
      years: [], // Years for dropdown
      weeks: [], // Weeks for dropdown
      error: null, // To store error messages
    }
  },
   created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
    this.listFilesFromBackend();
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden);
  },
  
  methods: {
    // Fetch files from backend
    async listFilesFromBackend() {
      try {
        const accessToken = JSON.parse(localStorage.getItem('userData')).accessToken;
        const response = await axios.get(`${serverUri}/settlement/getsetfiles`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          }
        });

        this.files = response.data.files;
        this.extractYearsAndWeeks();
        this.filterFiles(); // Initial filtering to display all files
      } catch (error) {
        console.error('Error fetching files from backend:', error);
        this.error = 'Failed to fetch files.';
      }
    },

    // Extract year and week from file names
    extractYearsAndWeeks() {
      const yearSet = new Set();
      const weekSet = new Set();

      this.files.forEach(file => {
        const [companyId, year, week] = file.split('_');
        yearSet.add(year);
        weekSet.add(week);
      });

      this.years = Array.from(yearSet).sort();
      this.weeks = Array.from(weekSet).sort();
    },

    // Filter files based on selected year and week
    filterFiles() {
      this.filteredFiles = this.files.filter(file => {
        const [companyId, year, week] = file.split('_');
        return (
          (!this.selectedYear || year === this.selectedYear) &&
          (!this.selectedWeek || week === this.selectedWeek)
        );
      });
    },
    async deleteFile(file) {
      try {
        const accessToken = JSON.parse(localStorage.getItem('userData')).accessToken;
        await axios.delete(`${serverUri}/settlement/delete`, {
          data: { file },
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        this.files = this.files.filter(f => f !== file);
        this.filterFiles();
      } catch (error) {
        this.error = 'Failed to delete file.';
      }
    },
    // Handle file download
    async downloadFile(file) {
      try {
        const accessToken = JSON.parse(localStorage.getItem('userData')).accessToken;
        const response = await axios.get(`${serverUri}/settlement/download`, {
          params: { file },
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          responseType: 'blob', // This will handle the file download
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading file:', error);
        this.error = 'Failed to download file.';
      }
    },

    // Dummy compare function
    compare() {
      console.log('Comparing files:', this.selectedFiles);
      // Future implementation will compare the selected files
    }
  }
}
</script>

<style scoped>
.custom-card {
  border-width: 3px !important;
  border-radius: 8px;
  min-width: 400px;
  max-width: 45%;
  padding: 20px;
  margin: 0 auto;
}

.file-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.filter-dropdowns {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.filter-dropdowns .b-form-group {
  margin-left: 10px;
}
</style>
